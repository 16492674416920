<template>
  <div>
    <!-- Toolbar -->
    <div class="toolbar d-flex flex-wrap justify-content-center justify-content-md-between mb-2">
      <div class="d-flex">
        <button v-if="showSelectAll"
                class="btn btn-outline-primary btn-sm mr-2"
                @click="selectAll()">
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all") }}
        </button>

        <button v-if="showSelectAll"
                class="btn btn-outline-primary btn-sm mr-2"
                @click="selectAll(true)">
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all_not_printed") }}
        </button>

        <!-- <button
          v-if="!sales_agent"
          class="btn btn-outline-primary btn-sm mr-2"
          data-toggle="modal"
          data-target="#orders-import-modal"
        >
          <i class="fal fa-cloud-download mr-2"></i>
          {{ $t("general.import") }}
        </button> -->

        <button class="btn btn-outline-primary btn-sm mr-2"
                v-if="selectedOrders && selectedOrders.length > 0"
                data-toggle="modal"
                data-target="#orders-print-labels"
                @click="() => (printUrl = null)">
          <i class="fal fa-print mr-2"></i>
          {{ $t("shipment.buttons.print_label") }} ({{ selectedOrders.length }})
        </button>

        <button class="btn btn-outline-primary btn-sm mr-2"
                v-if="selectedOrders && selectedOrders.length > 0"
                data-toggle="modal"
                :data-target="currentTabStatus === -1 ? '#mass-draft-move-modal' : '#orders-status-modal'">
          <i class="fal fa-exchange mr-2"></i>
          {{ $t("shipment.buttons.change_status") }}
        </button>

        <!-- Show only error -->
        <button v-if="showSelectAll"
                class="btn btn-outline-danger btn-sm mr-2"
                @click="showOnlyErrorShipments()">
          <i class="fal fa-exclamation-triangle"></i>
          {{ $t("shipment.buttons.show_only_errors") }}
        </button>

        <!-- Return Processing -->
        <button v-if="currentTabStatus == 8"
                class="btn btn-outline-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#return-processing-modal">
          {{ $t("return_processing.button") }}
        </button>

        <!-- Mass Cancel -->
        <button v-if="selectedOrders.length > 0"
                class="btn btn-outline-danger btn-sm mr-2"
                data-toggle="modal"
                data-target="#mass-cancel-modal">
          ยกเลิก
        </button>

        <!-- Shops Filter -->
        <select class="form-control form-control-sm align-self-center"
                v-model="selectedOrderShop"
                @change="handleShopFilterChange"
                :style="{ width: '8rem' }">
          <option :value="shop.id"
                  v-for="shop in orderShops"
                  :key="shop.id">
            {{ shop.name }}
          </option>
        </select>
      </div>

      <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
        <div class="input-group align-self-center mr-2 mt-2 mt-md-0 mb-2 mb-md-0"
             style="width: 22rem">
          <select class="form-control form-control-sm"
                  v-model="search.column">
            <option value="trackingNo">{{ $t("search.tracking_no") }}</option>
            <option value="phoneNo">{{ $t("search.phone_no") }}</option>
            <option value="customerName">{{ $t("search.customer") }}</option>
            <option value="remarks">{{ $t("search.remarks") }}</option>
          </select>

          <input type="text"
                 class="form-control form-control-sm flex-1"
                 :placeholder="$t('search.search_keyword')"
                 v-model.trim="search.keyword"
                 style="width: 8rem"
                 @keydown.enter="searchOrders()" />
          <div class="input-group-append">
            <button class="btn btn-outline-success btn-sm"
                    type="button"
                    @click="searchOrders()">
              <i class="fal fa-search"></i>
            </button>
          </div>
        </div>

        <router-link v-if="!sales_agent"
                     :to="{ name: 'Seller-Order-Super-Quick-Create' }"
                     class="btn btn-primary btn-sm mr-2">
          <i class="fal fa-bolt"></i>
        </router-link>

        <router-link v-if="!sales_agent"
                     :to="{ name: 'Seller-Order-Quick-Create' }"
                     class="btn btn-success btn-sm"
                     :class="{
                      'mt-2 mt-md-0': selectedOrders && selectedOrders.length > 0,
                    }"
                     style="width: 8rem">
          <i class="fal fa-plus-circle mr-1"></i>
          {{ $t("form.shipment.quick_create") }}
        </router-link>
      </div>
    </div>
    <!-- <div class="alert alert-info my-3">
      ระบบนับตัวเลขกำลังทำการนับใหม่
      หากตัวเลขจำนวนออเดอร์ในสถานะไม่ตรงกับออเดอร์จริงที่มีอยู่
      ระบบจะทำการนับใหม่ในวันที่ 15.2.2023
    </div> -->
    <!-- tab -->
    <div class="order-status-list">
      <Tabs v-if="orderCounts"
            id="order-list-tabs"
            class="is-primary"
            :tabs="orderCounts"
            :tabClasses="[
              'default',
              'default',
              'warning',
              'primary',
              'info',
              'danger',
              'success',
              'normal',
              'danger',
            ]"
            @tabSelected="handleTabChange"
            :active_tab_index="activetabIndex"
            :manualCron="true"
            :manualCronLoading="manualCronLoading"
            :manualCronLoadingStatus="manualCronLoadingStatus"
            @runCron="runManualCron" />
    </div>

    <slot name="top"></slot>

    <div v-if="paginatedOrders && !loading">
      <div v-if="paginatedOrders.length"
           class="table-responsive">
        <table class="table _datatable orders-table --very-small-height">
          <thead>
            <tr>
              <th>#</th>
              <th>วันที่</th>
              <th>เวลา</th>
              <th>{{ $t("shipment.table_labels.customer") }}</th>
              <th>{{ $t("general.phone") }}</th>
              <th>{{ $t("shipment.table_labels.status") }}</th>
              <th>{{ $t("shipment.table_labels.shipping") }}</th>
              <th>{{ $t("shipment.table_labels.tracking_no") }}</th>
              <th>
                COD
                <i class="fas fa-cog"
                   style="cursor: pointer"
                   @click="handleCodFilterTriggerBtnClick"></i>
              </th>
              <th>ร้านค้า</th>
              <th>{{ $t("general.actions") }}</th>
            </tr>
          </thead>
          <tbody :class="{ '--loading': miniLoading }">
            <tr v-for="(row, index) in computedOrdersList"
                :key="index">
              <td class="--checkbox">
                <input v-if="currentTabStatus == 1 || currentTabStatus == 2 || currentTabStatus == -1"
                       type="checkbox"
                       :id="'order-checkbox-' + row.id"
                       :ref="'order-checkbox-' + row.id"
                       class="table-checkbox"
                       @change="() => toggleSelect(row, true)" />

                <i v-if="row.printed"
                   title="Printed"
                   class="fal fa-print text-success ml-2 d-inline"></i>
              </td>
              <td v-html="tdHtml('createdAtDate', row.createdAt)"></td>
              <td v-html="tdHtml('createdAtTime', row.createdAt)"></td>

              <td v-html="tdHtml('ellipsis', row.custName)"
                  class="text-nowrap"></td>

              <td>{{ row.custPhone }}</td>

              <td v-html="tdHtml('status', row.status)"
                  class="--width-md"></td>

              <td class="--width-md">
                {{ row.shippingMethod.replace("thaipost0", "eps") }}
              </td>

              <td>
                <a :href="'https://track.proship.co.th/#/?barcode=' + row.trackingNo
                  "
                   class="mr-1"
                   target="_blank">{{ row.trackingNo }}</a>
                <span v-if="row.trackingNo"
                      class="--text-vsm">📨</span>

                <i class="fas fa-store text-color-3"
                   v-if="row.returnReason === 'shop'"></i>
                <i class="fas fa-user text-color-3"
                   v-else-if="row.returnReason === 'customer'"></i>
              </td>

              <td class="--width-md">
                {{ row.codAmount }}

                <i class="fas fa-check-circle text-success ml-1"
                   v-if="row.codPaid"></i>
              </td>

              <td v-html="tdHtml('ellipsis', row.shopName)"
                  class="text-nowrap"></td>

              <td>
                <span v-if="row.hasError"
                      class="text-danger mr-2"
                      :title="$t('warning.import_error_message')">
                  <i class="fal fa-exclamation-triangle"></i>
                </span>

                <Button variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-show"
                        @click="goto('Edit', row.id)"
                        v-if="row.status === 5 ||
                          (row.hasError && row.shippingMethod == 'jt')
                        ">
                  <i class="fal fa-redo"></i>
                </Button>

                <Button v-if="row.status > 0"
                        variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-show"
                        @click="goto('Show', row.id, true)">
                  <i class="fal fa-eye"></i>
                </Button>

                <Button v-if="(allowEditStatus.includes(row.status) && row.shippingMethod !== 'kerry') || row.status === -1"
                        variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-edit"
                        @click="goto('Edit', row.id)">
                  <i class="fal fa-edit"></i>
                </Button>

                <Button v-if="allowCancelStatus.includes(row.status) &&
                  row.shippingMethod != 'kerry'
                "
                        variant="link"
                        custom_class="p-0 text-color-5 mr-2"
                        size="sm"
                        id="btn-action-delete"
                        @click="deleteItem(row.id)">
                  <i class="fal fa-times-circle"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between">
          <div>
            <select class="form-control"
                    v-model="perPage"
                    @change="handlePerPageChange()">
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2500">2500</option>
            </select>
          </div>

          <div class="d-flex">
            <p class="align-self-center mr-2 text-color-4">
              Page {{ page }}
            </p>

            <div class="btn-group"
                 role="group">
              <Button type="button"
                      variant="outline-primary"
                      size="lg"
                      id="btn-paginate-prev"
                      @click="paginate(false)"
                      :disabled="page === 1">
                <i class="fal fa-long-arrow-left"></i>
              </Button>

              <Button type="button"
                      variant="outline-primary"
                      size="lg"
                      id="btn-paginate-prev"
                      @click="paginate(true)"
                      :disabled="page === lastPage"
                      :loading="miniLoading">
                <i class="fal fa-long-arrow-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!paginatedOrders.length && search.keyword">
        <div class="alert alert-warning">
          ระบบค้นหาไม่พบข้อมูลที่คุณค้นหา ข้อมูลของคุณอาจผิดพลาด
          หรือระบบค้นหาอาจทำงานผิดพลาด
        </div>
      </div>
    </div>

    <div v-else>
      <Spinner :variant="color_scheme"
               size="md" />
    </div>

    <!-- COD filter modal -->
    <Modal id="cod-filter-modal"
           backdrop>
      <template slot="header">
        <h5 class="modal-title pt-0">กรองจากยอด COD</h5>
      </template>
      <template slot="body">
        <div class="d-flex">
          <NumberField type="number"
                       id="codFilterMin"
                       v-model="codFilterForm.min"
                       label="จาก" />
          <div class="m-2"></div>
          <NumberField type="number"
                       id="codFilterMax"
                       v-model="codFilterForm.max"
                       label="ถึง" />
        </div>
      </template>
    </Modal>

    <!-- Import file modal -->
    <Modal id="orders-import-modal"
           backdrop>
      <template slot="header">
        <h5 class="modal-title pt-0">
          <i class="fal fa-file-import mr-2"></i>
          {{ $t("alert.import.lead_text") }}
        </h5>
      </template>
      <template slot="body">
        <p class="lead text-center"
           v-if="importLoading">
          {{ $t("alert.import.loading_message") }}
        </p>

        <div v-else>
          <div class="row">
            <div class="col">
              <Select v-if="shops"
                      id="shops"
                      :label="$t('alert.import.select_shop')"
                      :options="shops"
                      v-model="selectedShop"
                      @input="handleShopChange" />
            </div>

            <div class="col">
              <Select v-if="shippingMethods && shippingMethods.length"
                      id="shippingMethods"
                      :label="$t('shipment.edit.fields.shipping_methods')"
                      :options="shippingMethods"
                      v-model="selectedShippingMethod"
                      @input="handleShippingChange" />
            </div>
          </div>

          <div v-if="selectedShop">
            <DropZone @uploaded="importOrders"
                      :label="'ลากไฟล์มาวางตรงนี้ หรือกดเพื่อเลือกไฟล์จากเครื่องของคุณ ระบบจะนำเข้าไฟล์ตามไฟล์ตัวอย่างเท่านั้น ท่านสามารถโหลดไฟล์ตัวอย่างได้จากลิ้งค์ด้านล่าง'" />

            <p class="text-center mt-2 --text-sm">
              <a :href="sampleFileLink"
                 target="_blank"
                 rel="noopener noreferrer"
                 class="text-danger"
                 style="text-decoration: underline">
                {{ $t("alert.import.sample_file_text") }}</a>
            </p>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Print Label modal -->
    <Modal id="orders-print-labels"
           custom_dialog_class="modal-dialog-centered"
           :backdrop="true">
      <template slot="body"
                v-if="printLabels">

        <div class="d-flex justify-content-between mb-4">
          <p class="lead text-center align-self-center">

            <span v-if="printLabelLoading">
              กำลังพิมพ์ใบปะหน้าจำนวน {{ selectedOrders ? selectedOrders.length : 0 }} ใบ
            </span>

            <span v-else-if="printUrl">
              ใบปะหน้าพร้อมแล้ว {{ selectedOrders ? selectedOrders.length : 0 }} ใบ
            </span>

            <span v-else>
              {{ $t("shipment.shipping_label.message") }}
            </span>
          </p>

          <Button id="btn-close-print-dialog"
                  variant="default"
                  size="sm"
                  @click="cancelModal('orders-print-labels')"
                  :disabled="printLabelLoading">
            <i class="fa fa-times"></i>
          </Button>
        </div>

        <div class="alert alert-success mb-2"
             v-if="printLabelLoading"
             style="font-size: 1rem">
          {{ $t("print_dialog.generating_link") }}

          <div class="progress mt-2"
               style="height: 4px; background-color: white;">
            <div class="progress-bar bg-success"
                 role="progressbar"
                 :style="{ width: `${printLabelLoadingProgress}%` }"
                 :aria-valuenow="printLabelLoadingProgress"
                 aria-valuemin="0"
                 aria-valuemax="100"></div>
          </div>
        </div>

        <div class="text-center mb-2 mt-2"
             id="print-message"
             v-if="printUrl">
          <a :href="printUrl"
             target="_blank"
             rel="noopener noreferrer"
             class="btn btn-success mr-2"
             @click="addPrintIcon()">
            {{ $t("print_dialog.click_to_download") }}
          </a>
        </div>

        <div class="d-flex flex-column"
             v-else-if="!printLabelLoading && !printUrl">
          <!-- Paper size selector -->
          <div class="flex-grow-1">
            <label>โปรดเลือกขนาดกระดาษที่ต้องการใช้งาน</label>
            <select class="form-control form-control-sm"
                    v-model="selectedPaperSize"
                    @change="setPaperSize">
              <option :value="paperSize"
                      v-for="paperSize in Object.keys(printLabels.paperSizes)"
                      :key="paperSize">
                {{ paperSize }}
              </option>
            </select>
          </div>

          <!-- search-by-products -->
          <div class="mt-1 d-flex">
            <input type="checkbox"
                   id="sort-by-products"
                   v-model="sortByProducts"
                   class="align-self-center" />
            <label for="sort-by-products"
                   class="text-xs align-self-center mb-0 ml-1">
              เรียงตามรายการสินค้า
            </label>
          </div>

          <!-- print selector -->
          <div class="d-flex flex-wrap printerFormatContainer mt-4">
            <div class="printerFormat"
                 v-for="(prtFr, index) in printLabels.paperSizes[selectedPaperSize]"
                 :key="index"
                 @click="printLabel(prtFr.size, prtFr.printer)">
              <div>
                <div class="d-flex">
                  <img :src="prtFr.image"
                       class="icon align-self-start" />
                  <div class="d-flex flex-column align-self-center">
                    <p class="mb-0 --text-vsm">{{ prtFr.name }}</p>
                    <p class="--text-vsm text-color-5 mt-n2"
                       v-if="prtFr.sizeToShow">
                      {{ prtFr.sizeToShow }}
                    </p>
                  </div>
                </div>

                <p class="--text-vvsm text-color-5">{{ prtFr.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Change Status Modal -->
    <Modal id="orders-status-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center">
            {{ $t("shipment.shipment_status.title") }}
          </p>

          <div class="alert alert-success mt-4"
               v-if="selectedOrdersDict">
            <p v-for="(statusData, status) in selectedOrdersDict"
               :key="status">
              {{ status }}: {{ statusData.count }}
            </p>
          </div>
          <div v-else
               class="alert alert-danger mt-4">
            {{ $t("shipment.shipment_status.select_pending_message") }}
          </div>

          <div class="d-flex justify-content-center mt-4">
            <Button id="btn-change-status-forward"
                    variant="primary"
                    size="sm"
                    @click="changeStatus()"
                    :loading="statusUpdateLoading"
                    :disabled="!selectedOrdersDict">{{
                      $t("shipment.shipment_status.change_status_button_text")
                    }}</Button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Return Processing Modal -->
    <Modal id="return-processing-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center">{{ $t("return_processing.button") }}</p>

          <ValidationObserver v-slot="{ invalid }">
            <TextBox type="text"
                     id="trackingNo"
                     :label="$t('return_processing.enter_tracking_number')"
                     v-model="returnProcessingForm.trackingNo"
                     rules="required" />

            <p class="lead mb-2">
              {{ $t("return_processing.reason_for_return") }}
            </p>

            <validation-provider rules="required">
              <input type="radio"
                     id="shop"
                     value="shop"
                     v-model="returnProcessingForm.reason"
                     class="mr-1" />
              <label for="shop"
                     class="mr-2">
                {{ $t("return_processing.shipped_by_mistake") }}
                <i class="fas fa-store"></i>
              </label>
              <br />
              <input type="radio"
                     id="customer"
                     value="customer"
                     v-model="returnProcessingForm.reason"
                     class="mr-1" />
              <label for="customer"
                     class="mr-2">
                {{ $t("return_processing.customer_return") }}
                <i class="fas fa-user"></i>
              </label>
            </validation-provider>

            <div class="d-flex justify-content-center mt-4">
              <Button id="btn-change-status-forward"
                      variant="primary"
                      size="sm"
                      @click="handleReturnProcessingSubmit()"
                      :loading="returnProcessingLoading"
                      :disabled="returnProcessingLoading || invalid">{{ $t("general.submit")
                }}</Button>
            </div>
          </ValidationObserver>
        </div>
      </template>
    </Modal>

    <!-- Mass Cancel Modal -->
    <Modal id="mass-cancel-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center mb-3">
            {{ $t("mass_cancel.cancel_order") }}
          </p>

          <p class="text-color-4 text-center"
             v-if="massCancel.loading">
            {{ $t("mass_cancel.prosessing_msg_p1") }}
            {{ massCancel.total - massCancel.remaining + 1 }} /
            {{ massCancel.total }}
          </p>
          <p class="text-color-4 text-center"
             v-else>
            {{ $t("mass_cancel.confirm_msg_p1") }} {{ selectedOrders.length }}
            {{ $t("mass_cancel.confirm_msg_p2") }} ?
          </p>

          <div class="d-flex justify-content-center mt-4">
            <button type="button"
                    class="btn btn-secondary btn-sm mr-2"
                    data-dismiss="modal">
              {{ $t("general.no") }}
            </button>

            <Button id="btn-mass-cancel"
                    variant="primary"
                    size="sm"
                    @click="handleMassCancel()"
                    :loading="massCancel.loading"
                    :disabled="massCancel.loading">
              {{ $t("general.yes") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Mass draft move -->
    <Modal id="mass-draft-move-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center mb-3">
            เปลี่ยนสถานะการจัดส่ง
          </p>

          <div class="alert alert-success">
            <p>
              แบบร่าง to เตรียมส่ง : {{ massDraftMove.loading ? `${massDraftMove.total - massDraftMove.remaining + 1} /
              ${massDraftMove.total}` : selectedOrders.length }}
            </p>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <Button id="btn-mass-draft-move"
                    variant="primary"
                    size="sm"
                    @click="handleMassDraftMove()"
                    :loading="massDraftMove.loading"
                    :disabled="massDraftMove.loading">
              เปลี่ยนสถานะ
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import NumberField from "@/components/core/TextBox";
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";
import Tabs from "@/components/core/Tabs";
import DropZone from "@/components/derived/DropZone";
import CopyButton from "@/components/derived/CopyButton";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getOrderStatus } from "@/helpers/core";
import { i18n } from "@/i18n";
import { printLabels } from "@/helpers/print";
import Select from "@/components/core/Select";
import { FILTERS } from "@/helpers/filters";
import { Toast, ToastFix } from "@/helpers/toastr";
import { http } from "@/helpers/http";
import { printHtmlLabels } from "@/helpers/htmlPrinter";

export default {
  name: "OrdersTable",
  components: {
    TextBox,
    NumberField,
    DataTable,
    Modal,
    vueDropzone: vue2Dropzone,
    Button,
    Spinner,
    Tabs,
    DropZone,
    Select,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    create_path_name: {
      type: String,
    },
    detail_path_name: {
      type: String,
    },
    edit_path_name: {
      type: String,
    },
    color_scheme: {
      type: String,
    },
    sample_file_link: {
      type: String,
    },
    sales_agent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderRows: null,
      orderCounts: null,
      LastEvaluatedKey: null,
      paginatedOrders: null,
      dataSource: "dd",

      loading: false,
      miniLoading: false,
      modalLoading: false,
      statusUpdateLoading: false,
      importLoading: false,
      returnProcessingLoading: false,

      manualCronLoading: false,
      manualCronLoadingStatus: false,

      massCancel: {
        loading: false,
        total: 0,
        remaining: 0,
      },

      massDraftMove: {
        loading: false,
        total: 0,
        remaining: 0,
      },

      allowEditStatus: [1, 2, 7],
      allowCancelStatus: [1, 2],
      selectedOrders: [],
      selectedStatus: null,

      page: 1,
      perPage: 100,
      lastPage: null,
      selectAllStatus: false,
      currentTabStatus: 0,

      printLabelLoading: false,
      printLabelLoadingProgress: 0,
      printLabelLoadingTimer: null,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),
      selectedShopPrint: null,
      printUrl: null,

      showOnlyErrorOrders: false,

      shops: null,
      shopsApiData: null,
      selectedShop: null,
      sampleFileLink: null,
      shippingMethods: null,
      selectedShippingMethod: null,
      hasBoxSizes: false,

      codFilterForm: { min: null, max: null },

      // filter by shop
      orderShops: null,
      selectedOrderShop: null,
      sortByProducts: false,

      search: {
        column: "trackingNo",
        keyword: null,
      },

      returnProcessingForm: {
        trackingNo: null,
        reason: null,
      },
    };
  },

  created() {
    this.orderCounts = [
      { label: i18n.t("tabs.all"), value: 0 },
      { label: "แบบร่าง", value: -1 },
      { label: i18n.t("tabs.pending"), value: 1 },
      { label: i18n.t("tabs.to_ship"), value: 2 },
      { label: i18n.t("tabs.shipped"), value: 3 },
      { label: i18n.t("tabs.error"), value: 6 },
      { label: i18n.t("tabs.delivered"), value: 4 },
      { label: i18n.t("status.return_to_sender"), value: 8 },
      { label: i18n.t("tabs.cancelled"), value: 5 },
    ];

    //fetch shops
    this.fetchShops();

    // determines file sample to download
    setTimeout(() => this.fetchBoxSizes(), 1000);

    // determines print labels to show
    this.printLabels = printLabels()
    this.$store.dispatch("settings/fetchPrint", {
      callback: (status, data) => {
        if (status) {
          this.printLabels = printLabels(data);
        }
      }
    });

    // autofill search
    if (
      this.$route.params.prefillData &&
      this.$route.params.prefillData.search &&
      this.$route.params.prefillData.search.column &&
      this.$route.params.prefillData.search.keyword
    ) {
      this.search = this.$route.params.prefillData.search;
      this.searchOrders();
    }
    // retrieve selected tab from storage; also calls fetchOrders
    else
      this.currentTabStatus = parseInt(
        localStorage.getItem("currentTabStatus")
      );
  },

  mounted() {
    document.addEventListener("print-success", (data) => {
      this.printUrl = data.detail;
      this.printLabelLoading = false;
    });
  },

  methods: {

    handleShopFilterChange() {
      for (let i = 0; i < this.selectedOrders.length; i++) {
        this.toggleSelect(this.selectedOrders[i])
      }
      this.handlePageChange()
    },

    handleCodFilterTriggerBtnClick() {
      $("#cod-filter-modal").modal("show");
    },

    handleBtnCodFilterApplyClick() {
      $("#cod-filter-modal").modal("hide");
    },

    runManualCron(status) {
      this.manualCronLoading = true;
      this.manualCronLoadingStatus = status;
      this.$store.dispatch("orders/submitManualCron", {
        data: { orderStatus: status },
        callback: (status, data) => {
          if (status) Toast("ระบบกำลังปรับสถานะการจัดส่ง", "success");
          this.manualCronLoading = false;
          this.manualCronLoadingStatus = null;
        },
      });
    },

    setPaperSize() {
      localStorage.setItem("paperSize", this.selectedPaperSize);
      this.printUrl = null;
    },

    handleReturnProcessingSubmit() {
      this.returnProcessingLoading = true;
      this.$store.dispatch("orders/submitReturnProcessing", {
        data: this.returnProcessingForm,
        callback: (status, data) => {
          if (status) {
            ToastFix(this.returnProcessingForm.trackingNo, "success");
            this.returnProcessingForm.trackingNo = null;
            this.returnProcessingForm.reason = null;
            this.handleTabChange(this.currentTabStatus);
          }
          this.returnProcessingLoading = false;
        },
      });
    },

    tdHtml(type, value) {
      const types = {
        status: (value) => {
          let statusData = getOrderStatus(value);
          if (!statusData) return value;
          return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
        },

        createdAtDate: (value) => {
          return FILTERS.formatDate(value);
        },

        createdAtTime: (value) => {
          return FILTERS.formatTime(value);
        },

        ellipsis: (value) => {
          return FILTERS.ellipsis(value, 15);
        },
      };
      return types[type] ? types[type](value) : value;
    },

    paginate(forward = true) {
      if (forward) {
        if (!(this.lastPage && this.page === this.lastPage))
          this.page = this.page + 1;
      } else {
        this.page = Math.max(--this.page, 1);
      }
      this.handlePageChange();
    },

    fetchOrders(value, firstPage = true, _callback) {
      if (firstPage) this.loading = true;
      else this.miniLoading = true;

      if (
        this.authData &&
        this.authData.user &&
        this.authData.user.id &&
        this.authData.user.id ==
        "user-b3af45a0-6fb7-11ed-9892-3ff897dbdc941669707030266"
      ) {
        this.dataSource = "es";
        this.perPage = 10000;
      }

      let ACTION = this.sales_agent ? "fetchSalesAgentOrders" : "fetchOrders";
      this.$store.dispatch("orders/" + ACTION, {
        statusCode: value > 0 ? value : (value === -1 ? -1 : null),
        LastEvaluatedKey: !firstPage
          ? JSON.stringify(this.LastEvaluatedKey)
          : null,
        perPage: this.perPage,
        onlyError: this.showOnlyErrorOrders,
        dataSource: this.dataSource,
        callback: (status, data) => {
          if (status) {
            this.LastEvaluatedKey = data.LastEvaluatedKey;

            if (firstPage) {
              this.orderRows = data.orders;
              this.page = 1;
              this.lastPage = null;
            } else {
              this.orderRows = this.orderRows.concat(data.orders);
            }

            this.orderCounts = [
              {
                label: i18n.t("tabs.all"),
                number: data.orderCounts[0],
                value: 0,
              },
              {
                label: "แบบร่าง",
                number: data.orderCounts['-1'],
                value: -1,
              },
              {
                label: i18n.t("tabs.pending"),
                number: data.orderCounts[1],
                value: 1,
              },
              {
                label: i18n.t("tabs.to_ship"),
                number: data.orderCounts[2],
                value: 2,
              },
              {
                label: i18n.t("tabs.shipped"),
                number: data.orderCounts[3],
                value: 3,
              },
              {
                label: i18n.t("tabs.error"),
                number: data.orderCounts[6],
                value: 6,
              },
              {
                label: i18n.t("tabs.delivered"),
                number: data.orderCounts[4],
                value: 4,
              },
              {
                label: i18n.t("status.return_to_sender"),
                number: data.orderCounts[8],
                value: 8,
              },
              {
                label: i18n.t("tabs.cancelled"),
                number: data.orderCounts[5],
                value: 5,
              },
            ];

            // LOGIC: check from counter
            this.lastPage =
              data.orders.length < data.orderCounts[this.currentTabStatus]
                ? null
                : Math.ceil(this.orderRows.length / this.perPage);

            this.handlePageChange();
          }

          if (firstPage) this.loading = false;
          else this.miniLoading = false;

          if (_callback) _callback();
        },
      });
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    searchOrders() {
      let searchData = {
        ...this.search,
        status: this.currentTabStatus,
        salesAgent: this.sales_agent,
      };
      this.loading = true;
      // this.handleTabChange(0);
      this.$store.dispatch("orders/searchOrders", {
        ...searchData,
        callback: (status, data) => {
          this.orderRows = data.results.map((d) => {
            return {
              id: d.id,
              trackingNo: d.trackingNo,
              status: d.status,
              shippingMethod: d.shippingMethod,
              orderCode: d.orderCode,
              custName: d.customerName,
              custPhone: d.phoneNo,
              createdAt: d.createdAt,
              codAmount: d.codAmount,
              shopName: d.shopName,
              codPaid: d.codPaid,
              printed: d.printCount && d.printCount > 0,
            };
          });
          this.page = 1;
          this.lastPage = null;
          this.LastEvaluatedKey = null;
          this.handlePageChange();
          this.loading = false;
        },
      });
    },

    showOnlyErrorShipments() {
      this.showOnlyErrorOrders = true;
      this.handleTabChange(this.currentTabStatus);
    },

    handlePageChange() {
      let startIndex = (this.page - 1) * this.perPage;
      let endIndex = this.perPage * this.page - 1;

      // check if it needs data to load from server
      if (
        this.orderRows.length <= endIndex &&
        this.LastEvaluatedKey &&
        this.page != this.lastPage
      ) {
        // re-using LastEvaluatedKey to hold page no
        if (this.dataSource == "sql") this.LastEvaluatedKey = this.page;

        this.fetchOrders(this.currentTabStatus, false, () => {
          this.paginatedOrders = this.orderRows.slice(startIndex, endIndex + 1);
        });
      } else {
        this.paginatedOrders = this.orderRows.slice(startIndex, endIndex + 1);
      }

      //handle empty page
      if (this.paginatedOrders.length === 0) {
        this.page--;
        this.lastPage = this.page;
        this.handlePageChange();
        this.LastEvaluatedKey = null;
      }
      this.selectAllStatus = false;
      this.selectedOrders = [];

      // shops filter (unique by id)
      let shopsDict = {};
      this.orderShops = [];
      this.paginatedOrders.forEach((o) => (shopsDict[o.shopId] = o.shopName));
      Object.keys(shopsDict).forEach((shopId) =>
        this.orderShops.push({ id: shopId, name: shopsDict[shopId] })
      );
      this.orderShops.unshift({ id: null, name: "ทั้งหมด" });
    },

    handlePerPageChange() {
      this.page = 1;
      this.handlePageChange();
    },

    selectAll(notPrinted = false) {
      if (this.selectAllStatus) {
        $("tr.--selected").removeClass("--selected");
        $(".table-checkbox").prop("checked", false);
        this.selectedOrders = [];
      } else {
        let ordersToSelect = notPrinted
          ? this.computedOrdersList.filter((o) => !o.printed)
          : this.computedOrdersList;
        ordersToSelect.forEach((order) => this.toggleSelect(order));
      }
      this.selectAllStatus = !this.selectAllStatus;
    },

    toggleSelect(order, manual = false) {
      let checkboxElem = this.$refs["order-checkbox-" + order.id][0];
      if (!checkboxElem) return;
      if (manual) checkboxElem.checked = !checkboxElem.checked;
      let tableRow = checkboxElem.parentElement.parentElement;
      if (!tableRow) return;
      if (!checkboxElem.checked) {
        checkboxElem.checked = true;
        tableRow.classList.add("--selected");
        this.selectedOrders.push({
          id: order.id,
          status: order.status,
          createdAt: order.createdAt,
          products: order.products,
        });
      } else {
        let index = this.selectedOrders.findIndex((a) => a.id === order.id);
        this.selectedOrders.splice(index, 1);
        checkboxElem.checked = false;
        tableRow.classList.remove("--selected");
      }
    },

    handleTabChange(value) {
      this.currentTabStatus = value;
      localStorage.setItem("currentTabStatus", value);
      this.LastEvaluatedKey = null;

      // switch to es data source for 'delivered'
      if (value == 4) this.dataSource = "es";
      // if (value == 3) this.dataSource = "sql";
      else this.dataSource = "dd";

      // data source according to user id
      // const sqlWhiteList = [
      //   "user-6dd36840-d158-11ea-93cc-fb7968c618751595998831811", // vik@onechat.ai
      //   "user-f6b1aaf0-9787-11eb-8cf8-53881d49f3131617789578015", // chonnipam27@gmail.com
      //   "user-da2f8760-3487-11ec-949f-2fa00849aa211635051862742", //  ST.Store@hotmail.com
      //   "user-e8d394b0-bafb-11eb-ac73-d5dec46ffb141621687667067", // gibza25282528@gmail.com
      // ];

      // if (sqlWhiteList.includes(this.authData.user.id)) this.dataSource = "sql";

      this.fetchOrders(value, true, () => {
        //clear configs on tabs change
        this.selectAllStatus = false;
        this.selectedOrders = [];
        this.showOnlyErrorOrders = false;
      });
    },

    goto(type, id, newTab = false) {
      let routerData;

      if (type === "Show")
        routerData = { name: this.detail_path_name, params: { id } };
      else if (type === "Edit")
        routerData = { name: this.edit_path_name, params: { id } };
      else if (type === "TaxInvoice") {
        routerData = { name: "Seller-Order-Tax-Invoice", params: { id } };
      }

      if (newTab) window.open(this.$router.resolve(routerData).href, "_blank");
      else this.$router.push(routerData);
    },

    deleteItem(id) {
      Alert(
        i18n.t("general.cancel"),
        i18n.t("alert.cancel.message"),
        null,
        () => {
          this.loading = true;
          this.$store.dispatch("orders/deleteOrder", {
            id: id,
            callback: (status) => {
              if (status) this.$emit("deleted", id);
              this.loading = false;
            },
          });
        }
      );
    },

    async handleMassCancel() {
      if (!this.selectedOrders.length) return;

      this.massCancel.total = this.selectedOrders.length;
      this.massCancel.remaining = this.selectedOrders.length;
      this.massCancel.loading = true;

      for (let i = 0; i < this.selectedOrders.length; i++) {
        const orderId = this.selectedOrders[i].id;
        try {
          await http.delete(
            "https://pkp9f3293d.execute-api.ap-southeast-1.amazonaws.com/dev/v1/orders/" +
            orderId
          );
        } catch (e) {
          console.log(e);
        }
        this.massCancel.remaining--;

        if (this.massCancel.remaining === 0) {
          this.massCancel = {
            loading: false,
            total: 0,
            remaining: 0,
          };
          this.selectedOrders = [];
          $("#mass-cancel-modal").modal("hide");
          this.handleTabChange(this.currentTabStatus);
        }
      }
    },

    async handleMassDraftMove() {
      if (!this.selectedOrders.length) return;

      this.massDraftMove.total = this.selectedOrders.length;
      this.massDraftMove.remaining = this.selectedOrders.length;
      this.massDraftMove.loading = true;

      for (let i = 0; i < this.selectedOrders.length; i++) {
        const orderId = this.selectedOrders[i].id;
        try {
          await http.post(
            // `http://localhost:3000/dev/v1/orders/draft/${orderId}`
            `https://c8pqrpii2l.execute-api.ap-southeast-1.amazonaws.com/dev/v1/orders/draft/${orderId}`
          );
        } catch (e) {
          console.log(e);
        }
        // await new Promise(r => setTimeout(r, 2000));
        this.massDraftMove.remaining = this.massDraftMove.remaining - 1;

        if (this.massDraftMove.remaining === 0) {
          this.massDraftMove = {
            loading: false,
            total: 0,
            remaining: 0,
          };
          this.selectedOrders = [];
          $("#mass-draft-move-modal").modal("hide");
          this.handleTabChange(this.currentTabStatus);
        }
      }
    },

    importOrders(value) {
      var url = value[0];
      this.$emit("imported", {
        url,
        selectedShop: this.selectedShop,
        selectedShippingMethod: this.selectedShippingMethod,
      });

      //show loading message
      this.importLoading = true;
      setTimeout(() => {
        $("#orders-import-modal").modal("hide");
        this.importLoading = false;
      }, 2000);
    },

    async printLabel(size, printer) {
      this.printLabelLoading = true;
      this.printUrl = null;
      this.printLabelLoadingProgress = 0;

      let orders;
      if (this.sortByProducts) {
        // sort orders by product sku alphabetically ========================
        // 1. build product-sku array
        let PRODUCTS = [];
        this.selectedOrders.forEach((o) => {
          if (o.products)
            for (const pCode of Object.keys(o.products)) PRODUCTS.push(pCode);
        });
        PRODUCTS = [...new Set(PRODUCTS)].sort();

        // 2. specify print-rank according to dict
        orders = this.selectedOrders.map((o) => {
          o.printRank = 1;
          if (o.products) {
            let pCodes = Object.keys(o.products);
            if (pCodes && pCodes.length) {
              let firstPCode = pCodes[0];
              o.printRank = Math.max(PRODUCTS.indexOf(firstPCode), 1);
            }
          }
          return o;
        });

        // 3. sort by print-rank
        orders.sort((a, b) => b.printRank - a.printRank);
        // ========================================================
      } else orders = this.selectedOrders;


      if (printer === "proship_v1_html") {
        let orders = []

        const apiRequests = []
        for (const _order of this.selectedOrders)
          apiRequests.push(this.$store.dispatch("orders/fetchOrderDetailAsync", { id: _order.id }))

        const responses = await Promise.all(apiRequests)

        const ordersForUpdate = [];
        for (const resp of responses) {
          const { status, data } = resp;
          if (!status) continue;

          let order = JSON.parse(JSON.stringify(data));

          // only update count first time
          if (order && order.details && !order.details.printed) ordersForUpdate.push(order);

          for (const key in order.details) order[key] = order.details[key];
          orders.push(order);

          this.printLabelLoadingProgress = Math.min((orders.length / this.selectedOrders.length) * 100, 98);
        }

        // update print count
        await this.$store.dispatch("orders/updateOrderAsync", { data: { orders: ordersForUpdate } });

        // for (const order of ordersForUpdate) {
        //   if (order && order.details && !order.details.printed) {
        //     apiRequests.push(this.$store.dispatch("orders/updateOrderAsync", { id: `${order.id}__${order.createdAt}`, order: order.details }))
        //   }
        // }
        // await Promise.all(updateApiRequests)

        // print
        await printHtmlLabels(orders, size);
        this.printLabelLoading = false;
        this.printLabelLoadingProgress = 100;
        return;
      }

      this.$store.dispatch("orders/printLabels", {
        data: {
          orders: orders.map((o) => o.id + "|" + o.createdAt),
          size,
          printer,
        },
        callback: (status, data) => {
          if (status) {
            if (data.mode == "api") {
              this.printUrl = data.url;
              this.printLabelLoading = false;
              this.printLabelLoadingProgress = 100;
            } else
              document
                .querySelector("#orders-print-labels")
                .setAttribute("data-backdrop", "static");
          } else {
            this.printLabelLoading = false;
          }
        },
      });

      this.printLabelLoadingProgress = 0;
      clearInterval(this.printLabelLoadingTimer);

      // increase to first 50% faster
      this.printLabelLoadingTimer = setInterval(() => {
        if (this.printLabelLoadingProgress <= 50)
          this.printLabelLoadingProgress += 10;
        // increase it by 1 each 500ms
        else this.printLabelLoadingProgress += 1;

        this.printLabelLoadingProgress = Math.min(98, this.printLabelLoadingProgress);
      }, 500);
    },

    addPrintIcon() {
      // add print icon on frontend
      for (let i = 0; i < this.selectedOrders.length; i++) {
        const orderId = this.selectedOrders[i].id;
        let checkBox = document.getElementById("order-checkbox-" + orderId);
        if (!checkBox.parentNode.querySelector("i")) {
          let printIcon = document.createElement("i");
          printIcon.classList.add("fal", "fa-print", "text-success", "mr-2");
          checkBox.parentNode.appendChild(printIcon);
        }
      }
    },

    changeStatus(forward) {
      this.statusUpdateLoading = true;

      let _selectedOrders = this.selectedOrders
        .filter((o) => o.status == 1)
        .map((o) => {
          return {
            ...o,
            status: 2,
          };
        });

      this.$store.dispatch("orders/massUpdateOrderStatus", {
        data: { orders: _selectedOrders },
        callback: (status, data) => {
          if (status) {
            if (status) {
              this.handleTabChange(this.currentTabStatus);
              $("#orders-status-modal").modal("hide");
              ToastFix(data.message, "info");
            }
          }
          this.statusUpdateLoading = false;
        },
      });
    },

    cancelModal(id) {
      $("#" + id).modal("hide");
    },

    openModal(id) {
      $("#" + id).modal("show");
    },

    fetchShops() {
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shopsApiData = data;
            this.shops = data.map((s) => {
              return {
                name: s.details.name,
                value: s.id,
              };
            });
            this.shops.unshift({ name: "None", value: null });
            this.selectedShop = this.shops[0].id;
          }
        },
      });
    },

    handleShopChange() {
      let selectedShop = this.shopsApiData.find(
        (s) => s.id == this.selectedShop
      );
      if (selectedShop) {
        this.shippingMethods = selectedShop.details.shippingMethods
          .filter((sm) => sm != "shopee")
          .map((sm) => {
            return { name: sm, value: sm };
          });
      } else this.shippingMethods = [];
      if (this.shippingMethods.length)
        this.selectedShippingMethod = this.shippingMethods[0].value;
      this.handleShippingChange();
    },

    handleShippingChange() {
      let kerrySample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Kerry-Sample-Import.xlsx";
      let jtSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-JT-Sample-Import.xlsx";
      let flashSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Flash-Sample-Import.xlsx";
      let shopeeSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Shopee-Sample-Import.xlsx";
      let boxSizeSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/Import-Standard-Format-BoxSize.xlsx";

      let normalSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/ProShip-ThaiPost-Sample-Import-2.xlsx";

      if (this.selectedShippingMethod == "jt") {
        this.sampleFileLink = jtSample;
      } else if (this.selectedShippingMethod == "kerry") {
        this.sampleFileLink = kerrySample;
      } else if (this.selectedShippingMethod == "flash") {
        this.sampleFileLink = flashSample;
      }
      // else if (this.selectedShippingMethod == "shopee") {
      //   this.sampleFileLink = shopeeSample;
      // }
      else if (
        this.selectedShippingMethod &&
        this.selectedShippingMethod.includes("thaipost")
      ) {
        if (this.hasBoxSizes) this.sampleFileLink = boxSizeSample;
        else this.sampleFileLink = normalSample;
      }
    },

    fetchBoxSizes(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              if (data && data.length) this.hasBoxSizes = true;
            }
            if (callback) callback();
          },
        });
      }
    },
  },

  computed: {
    ...mapGetters("orders", ["activeStatus"]),
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
      return false;
    },

    buttonClass() {
      return `btn btn-sm btn-outline-${this.color_scheme} mr-2`;
    },

    selectedOrdersDict() {
      if (this.selectedOrders && this.selectedOrders.length) {
        let _selectedOrders = this.selectedOrders.filter((o) => o.status == 1);

        let obj = {};
        _selectedOrders.forEach((ord) => {
          let status =
            getOrderStatus(ord.status).title +
            " to " +
            (ord.status == 1
              ? getOrderStatus(2).title
              : getOrderStatus(1).title);
          if (obj[status]) obj[status].count++;
          else obj[status] = { count: 1 };
        });
        return obj;
      }
    },

    activetabIndex() {
      return this.currentTabStatus ? this.currentTabStatus : 0;
    },

    showSelectAll() {
      return (
        (this.currentTabStatus && this.currentTabStatus === 1) ||
        this.currentTabStatus === 2 || this.currentTabStatus === -1
      );
    },

    // shopsPrint() {
    //   return this.shops
    //     ? [{ name: "ทั้งหมด", value: null }].concat(
    //         this.shops.slice(1, this.shops.length)
    //       )
    //     : null;
    // },

    computedOrdersList() {
      // if (!this.selectedOrderShop) return this.paginatedOrders;
      let orders = this.paginatedOrders;

      if (this.selectedOrderShop)
        orders = orders.filter((o) => o.shopId == this.selectedOrderShop);

      let min = parseFloat(this.codFilterForm.min) || 0;
      let max = parseFloat(this.codFilterForm.max) || Infinity;
      return orders.filter((o) =>
        (o.codAmount && !isNaN(o.codAmount)) ? o.codAmount >= min && o.codAmount <= max : true
      );
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.shipping-label-format {
  text-align: center;
  padding: 0.5rem;
  background-color: rgba($brand-blue-light, 0.1);
  border-radius: toRem(10px);
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: toRem(100px);
    width: auto;
  }

  span {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
}

.btn-outline-primary {
  border: toRem(1.5px) solid;
}

.printerFormatContainer {
  width: 210px;

  @include for-larger-than-phone {
    width: 420px;
  }

  margin: 0 auto;
  overflow: auto;

  .printerFormat {
    width: 200px;
    background-color: rgb(252, 252, 252);
    padding: 0.25rem;
    border-radius: 3px;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .icon {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
</style>